import { apiRoute, authHeader, handleResponse } from '../helpers'

export const zipService = {
  poll
}

function poll (project) {
  const requestOptions = {
    method: 'get',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(apiRoute() + '/api/v1/admin/training/zip_poll/' + project.pid, requestOptions).then(handleResponse)
}
