const state = {
  uploading: false,
  files: []
}

const actions = {
  add ({ commit }, file) {
    commit('addFile', file)
  },
  start ({ dispatch, commit }) {
    commit('startUploading')
    dispatch('alert/clear', null, { root: true })
    dispatch('alert/success', 'Upload of images started ...', { root: true })
  },
  pause ({ dispatch, commit }) {
    commit('pauseUploading')
    dispatch('alert/clear', null, { root: true })
    dispatch('alert/warning', 'Upload of images paused ...', { root: true })
  },
  cancel ({ dispatch, commit }) {
    commit('cancelUploading')
    dispatch('alert/clear', null, { root: true })
    dispatch('alert/warning', 'Upload of images cancelled ...', { root: true })
  },
  clear ({ commit }) {
    commit('cancelUploading')
  }
}

const mutations = {
  addFile (state, file) {
    state.files.push(file)
  },
  startUploading (state) {
    if (!state.uploading) {
      state.uploading = true
    }
  },
  pauseUploading (state) {
    if (state.uploading) {
      state.uploading = false
    }
  },
  cancelUploading (state) {
    state.uploading = false
    state.files = []
  }
}

export const uploader = {
  namespaced: true,
  state,
  actions,
  mutations
}
