import { zipService } from '../services'

const state = {
  uploading: false,
  files: []
}

const actions = {
  poll ({ commit }, pId) {
    // console.log('polling zip', pId)
    zipService.poll(pId).then(
      (data) => commit('setPoll', data)
    )
  },
  setUpload ({ commit }, val) {
    commit('setUpdate', val)
  },
  clear ({ commit }) {
    commit('cancelUploading')
  }
}

const mutations = {
  setPoll (state, file) {
    // state.uploading = file.length && file.length > 0
    if (file.length > 0) {
      const _pro = file.filter(f => f.status === 'processing')
      if (_pro.length > 0) {
        state.uploading = true
      }
    }
    state.files = file
  },
  setUpdate (state, val) {
    state.uploading = val.val
  },
  cancelUploading (state) {
    state.uploading = false
    state.files = []
  }
}

export const zipUploader = {
  namespaced: true,
  state,
  actions,
  mutations
}
