<template>
    <div class="dashboard">
      <b-col>
        <div class="text-center my-4" v-if="!dataLoaded">
          <b-spinner scale="4" class="color-yellow"></b-spinner>
        <div>Loading Project Details...</div>
        </div>
      </b-col>
      <!--  -->
      <b-col v-if="dataLoaded">
          <b-row>
              <b-col cols="12">
                <b-row>
                      <b-col cols="6">
                  <b-card no-body class="h-100 bg-dark-navy custom border-1">
                    <b-card-header class="px-3 title_staging"><strong>Project Information</strong></b-card-header>
                      <div class="px-3 pt-1">
                        <div>
                          <strong>Created By:</strong> {{ user.name }} ({{ user.email }})
                        </div>
                        <div>
                          <strong>Date Created:</strong>
                          {{ formatDate(project.date_updated) }}
                        </div>
                        <div>
                          <strong>Members:</strong> {{ project.members.length }}
                        </div>
                        <div>
                          <strong>RATify Labeller Version:</strong> {{ project.version ? project.version === 1 ? 'v1': 'v1.2' : 'Error Loading Project Version'}}
                        </div>
                        <div>
                          <strong>DICOM Server Linked:</strong> {{ project.dicom_enabled ?  'Enabled': 'Disabled'}}
                        </div>
                        <div>
                          <strong>Image Type:</strong> {{ project.extra &&  project.extra.type ? capitalizeFirstLetter(project.extra.type):  'Error Loading Project Type'}}
                        </div>
                        <div>
                          <strong>Image Subtype:</strong> {{ project.extra &&  project.extra.subtype ? capitalizeFirstLetter(project.extra.subtype):  'Error Loading Project Subtype'}}
                        </div>
                        <div>
                          <strong>Image Grouping:</strong> {{ project.extra && project.extra.grouping ? project.extra.grouping === 'sets' ? capitalizeFirstLetter('Image (Instance)') :capitalizeFirstLetter(project.extra.grouping) : 'Error Loading Project Grouping'}}
                        </div>
                      </div>
                  </b-card>
                    </b-col>

                  <b-col cols="6">
                  <b-card no-body class="h-100 bg-dark-navy custom border-1">
                          <b-card-header class="px-3 title_staging"><strong>Project Description</strong></b-card-header>
                            <p class="px-3">{{ project.description || "No description available." }}</p>
                  </b-card>
                    </b-col>
                </b-row>

              </b-col>
              <!-- class="pb-5"  -->
              <b-col cols="12"  >
              <b-card no-body class="h-100 bg-dark-navy custom border-1"  @click="navigate(5)">
                <b-card-header class="px-3 title_staging"><strong>Project Set-Up</strong></b-card-header>
                  <b-row class="px-3 " align-h="center">
                            <b-col cols="4">
                              <div>
                                <strong >Tags:</strong> {{ project.tags.length }}
                                <b-col class="pt-2 px-0 setup_info">
                                    <p v-for="(tag) in project.tags" :key="tag._id">
                                      • {{ tag }}
                                    </p>
                                </b-col>
                            </div>
                            </b-col>
                            <b-col cols="4">
                              <div>
                                <strong>Pathologies: </strong> {{ project.pathologies.length }}
                                <b-col class="pt-2 px-0 setup_info">
                                    <p v-for="(path) in project.pathologies" :key="path._id">
                                      • {{ path.label }}
                                    </p>
                                </b-col>
                            </div>
                            </b-col>
                            <b-col cols="4">
                              <div>
                                <strong>Diagnoses:</strong> {{ project.diagnosis.length }}
                                <b-col class="pt-2 px-0 setup_info">
                                    <p v-for="(diagnos) in project.diagnosis" :key="diagnos._id">
                                      • {{ diagnos.label}}
                                    </p>
                                </b-col>
                            </div>
                            </b-col>
                  </b-row>
              </b-card>
              </b-col>
              <b-col class="pt-4">
              <b-card no-body class="h-100 bg-dark-navy custom border-1">
                <b-card-header class="px-3 title_staging"><strong>Project Data</strong></b-card-header>
                <b-row cols="12" @click="navigate(9)">
                  <b-col>
                  <b-card-title class="px-3 pt-2 title_staging">Members</b-card-title>
                  <p v-for="(mem) in members" :key="mem._id" class="px-3 pt-2">
                    • <strong class="title_staging">{{ mem.personal.fullname }}:</strong> {{ mem.projectRole.find(memRole) ? mem.projectRole.find(memRole).role : 'View Only' }}
                  </p>
                  </b-col>
                  <b-col >
                  <b-card-title class="px-3 pt-2 title_staging">Member Annotation</b-card-title>
                  <apexchart v-if="membersName.length>0" class="mt--4" type="bar"  :options="memberOptions" :series="memberSeries"></apexchart>
                  <p v-if="membersName.length===0" class="px-3 pt-2 title_staging">No User Annotations</p>
                 </b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" @click="navigate(1)">
                    <b-card-title class="px-3  pt-2 title_staging">All Images</b-card-title>
                  <apexchart class="mt--4" type="pie"   :options="options" :series="imageChartOptions.series"></apexchart>
                  </b-col>
                  <b-col cols="4" @click="navigate(3)">
                    <b-card-title class="px-3 pt-2 title_staging">Annotated Images</b-card-title>
                  <apexchart v-if="visibleImages.length>0" class="mt--4" type="pie"   :options="annotatedChartOptions" :series="annotatedChartOptions.series"></apexchart>
                    <p v-if="visibleImages.length===0" class="px-3 pt-2 title_staging">No Assigned Images</p>
                  </b-col>
                  <b-col cols="4" @click="navigate(3)">
                    <b-card-title class="px-3 pt-2 title_staging">Annotated Discordance</b-card-title>
                  <apexchart v-if="imgOver2>0" class="mt--4" type="pie"   :options="discordChartOptions" :series="discordChartOptions.series"></apexchart>
                    <p v-if="imgOver2===0" class="px-3 pt-2 title_staging">No Fully Annotated Images</p>
                  </b-col>

                </b-row>
              </b-card>
              </b-col>
          </b-row>
        </b-col>
    </div>
  </template>

<script>
import { mapState, mapActions } from 'vuex'
import { IOVThesholds } from '../../../../helpers/iovthresholds'

export default {
  data () {
    return {
      dataLoaded: false,
      allImgLoaded: false,
      assImgLoaded: false,
      annoImgLoaded: false,
      memLoaded: false,
      imageChartOptions: {},
      annotatedChartOptions: {},
      membersAnno: {},
      membersName: [],
      membersCount: [],
      imgOver2: 0,
      allImages: [],
      visibleImages: [],
      imagesAnnotatted: [],
      memberOptions: {
        chart: {
          id: 'Member Chart'
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: ['#FFF']
            }
          }
        }
      },
      memberSeries: [{
        name: 'Annotators',
        data: []
      }],
      options: {
        chart: {
        },
        legend: {
          show: true,
          position: 'bottom',
          labels: {
            colors: '#FFF'
          }
        },
        stroke: {
          colors: ['#0C2030']
        },
        labels: ['Available', 'Assigned'],
        colors: ['#F0B93B', '#0473b5']
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('labelledImagesSubmission', {
      imagesAnnotattedIn: (state) => state.labelledImages,
      imagesAnnotattedloading: (state) => state.labelledImages
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    ...mapState('projectSubmission', {
      projectImages: (state) => state.projectImages,
      projectImagesWatch: (state) => state.loadingProjectImages,
      allImagesStore: (state) => state.allImages,
      allImagesWatch: (state) => state.loadingAllImages
    }),
    detailsLoading () {
      return this.allImgLoaded && this.assImgLoaded && this.annoImgLoaded && this.memLoaded
    },
    imagesLoad () {
      return this.allImagesWatch
    },
    projectImagesLoad () {
      return this.projectImagesWatch
    },
    imagesLoadLabelled () {
      return this.imagesAnnotattedloading
    },
    readers () {
      if (this.members && this.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    }
  },
  watch: {
    imagesLoad (_new) {
      if (_new) {
        this.allImages = this.allImagesStore
        this.allImgLoaded = true
      }
    },
    projectImagesLoad (_new) {
      if (!_new) {
        if (this.projectImages && this.projectImages.length > 0) {
          this.visibleImages = this.projectImages
        } else {
          this.visibleImages = []
        }
        this.assImgLoaded = true
      }
    },
    imagesLoadLabelled (_new) {
      if (_new) {
        if (this.imagesAnnotattedIn) {
          this.imagesAnnotatted = this.imagesAnnotattedIn
        } else {
          this.imagesAnnotatted = []
        }
        this.mapAnnotatedImages()
      }
    },
    detailsLoading (_newvalue) {
      if (_newvalue) {
        this.dataLoaded = true
      }
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions('projectSubmission', ['getProjectImages2', 'getAllImages2']),
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2']),
    ...mapActions('usersSubmission', {
      getMembers: 'getMembers'
    }),
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    },
    capitalizeFirstLetter (val) {
      return String(val).charAt(0).toUpperCase() + String(val).slice(1)
    },
    memRole (item) {
      return item.project === this.project._id
    },
    navigate (i) {
      this.$emit('phase-select', i)
    },
    mapAnnotatedImages () {
      // // // console.log(this.imagesAnnotatted.length)
      var disCount = 0
      var conCount = 0
      var parCount = 0
      this.membersAnno = {}

      this.imagesAnnotatted.forEach(img => {
        var acc = img.accuracy
        // // // console.log('a', acc)
        // // // // console.log('set len', new Set(img.users).size)
        if (img && img.users && img.users.length >= this.readers.length) {
          this.imgOver2 += 1
          if (acc <= IOVThesholds().fullAgreement) {
            conCount += 1
          } else if (acc > IOVThesholds().fullAgreement && acc < IOVThesholds().partialAgreement) {
            parCount += 1
          } else {
            disCount += 1
          }
        }
        img.users.forEach(usr => {
          if (usr.name in this.membersAnno) {
            this.membersAnno[usr.name] += 1
          } else {
            this.membersAnno[usr.name] = 1
          }
        })
      })
      this.membersCount = Object.values(this.membersAnno)
      this.membersName = Object.keys(this.membersAnno)

      this.memberOptions = {
        chart: {
          width: 380,
          type: 'bar'
        },
        xaxis: {
          categories: this.membersName,
          labels: {
            style: {
              colors: '#fff'
            }
          },
          title: {
            text: 'Users',
            style: {
              color: '#fff'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: '#fff',
              formatter: function (val) {
                return val.toFixed(0)
              }
            }
          },
          title: {
            text: 'Annotations per user',
            style: {
              color: '#fff'
            }
          }
        }
      }
      this.memberSeries = [{
        name: 'Annotators',
        data: this.membersCount
      }]
      this.imageChartOptions = {
        series: [this.allImages.length, this.visibleImages.length]
      }
      this.annotatedChartOptions = {
        series: [this.visibleImages.length - this.imagesAnnotatted.length, this.imagesAnnotatted.length - this.imgOver2, this.imgOver2],
        chart: {
          width: 380,
          type: 'pie'
        },
        stroke: {
          colors: ['#0C2030']
        },
        labels: ['Unannotated', 'Annotated Once', 'Annotated >1'],
        legend: {
          show: true,
          position: 'bottom',
          labels: {
            colors: '#FFF'
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            },
            noData: {
              text: 'No data',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0
            }
          }
        }],
        colors: ['#ff5348', '#F0B93B', '#0473b5']
      }
      this.discordChartOptions = {
        series: [parCount, disCount, conCount],
        chart: {
          width: 380,
          type: 'pie'
        },
        stroke: {
          colors: ['#0C2030']
        },
        labels: ['Partial Agreement', 'Full Disagreement', 'Full Agreement'],
        legend: {
          show: true,
          position: 'bottom',
          labels: {
            colors: '#FFF'
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            },
            noData: {
              text: 'No data',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0
            }
          }
        }],
        colors: ['#F0B93B', '#ff5348', '#0473b5']
      }
      this.annoImgLoaded = true
    },
    async extractMembers () {
      await this.getMembers(this.project._id)
      this.memLoaded = true
    },
    async getProjectDetails () {
      await this.extractMembers()
    }
  },
  mounted () {
    if (this.project._id) {
      this.allImages = this.allImagesStore
      this.allImgLoaded = true
      if (this.projectImages && this.projectImages.length > 0) {
        this.visibleImages = this.projectImages
      } else {
        this.visibleImages = []
      }
      this.assImgLoaded = true
      if (this.imagesAnnotattedIn && this.imagesAnnotattedIn.length > 0) {
        this.imagesAnnotatted = this.imagesAnnotattedIn
      } else {
        this.imagesAnnotatted = []
      }
      this.assImgLoaded = true
      this.dataLoaded = true
      this.mapAnnotatedImages()
      // this.getProjectImages2(this.project._id)
      // this.getAllImages2(this.project._id)
      // this.fetchLabelledImages2(this.project._id)
      // this.getProjectDetails()
    }
  }
}
</script>

  <style scoped>
  .dashboard {
    padding: 20px;
  }

  .title_staging {
    color:azure
  }

  div > strong {
    color: aliceblue;
  }
  .title_staging  > strong {
    color: aliceblue;
  }

  .dashboard-header {
    margin-bottom: 20px;
  }

  .project-info-card,
  .project-description-card {
    width: 100%;
  }

  .dashboard-card {
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    background-color: #143040;
  }

  .dashboard-card .card-text {
    font-size: 18px;
    font-weight: bold;

  }
  .dashboard-card .card-title {
    background-color: yellow;
    padding: 8px;
    border-radius: 5px 5px 0 0;
  }

  .indicator {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #f0b93b;
    color: #181818;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .dashboard-card .card-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .dashboard-card .indicator:empty {
    display: none;
  }

  .dashboard-card .indicator:empty + .card-text {
    margin-top: 15px;
  }

  /* Additional styles for the project description */
  .project-description-card .card-text p {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
  }
  .yellow-card-title {
    background-color: yellow;
    padding: 8px;
    border-radius: 5px 5px 0 0;
  }

  .setup_info{
    height: 15vh;
    overflow-y: scroll;
  }

  @media (max-width: 767px) {
    .dashboard-header {
      display: block;
    }
    .project-info-card,
    .project-description-card {
      margin-bottom: 20px;
    }
  }
  </style>
